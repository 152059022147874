// Render handlers for construct content
// TODO: SODEV-38481 - this file will be generated at build time by
// finding calls to addContentHandler in the handler components
import loadable from '@loadable/component';
import { BrandSection } from './brand-section/brand-section.handler';
import { CallToAction } from './call-to-action/call-to-action.handler';
import { CallToActionSection } from './call-to-action-section/call-to-action-section.handler';
import { CategorySection } from './category-section/category-section.handler';
import { ContactUsPlaceholder } from './contact-us-placeholder/contact-us-placeholder.handler';
import { ContentAuthorHandler } from './content-author-handler/content-author-handler.component';
import { ContentCardHandler } from './content-card-handler/content-card.handler';
import { ListContentHandler } from './content-list/content-list.handler';
import { ContentSection } from './content-section/content-section.handler';
import { ContentSectionFull } from './content-section-full/content-section-full.handler';
import { DoorbusterSection } from './doorbuster-section/doorbuster-section.handler';
import { EmailFormSection } from './email-form-section/email-form-section.handler';
import { EmailSubscribePlaceholder } from './email-subscribe-placeholder/email-subscribe-placeholder.handler';
import { GeneralContentGroup } from './general-content-group/general-content-group.handler';
import { GroupItemsSection } from './group-items-section/group-items-section.handler';
import { HeroMediaHandler } from './hero-media-handler/hero-media.handler';
import { HorizontalDividerPlaceholder } from './horizontal-divider-placeholder/horizontal-divider-placeholder.handler';
import { MediaCard } from './media-card/media-card.handler';
import { MediaGroup } from './media-group/media-group.handler';
import { PullQuoteSection } from './pull-quote-section/pull-quote-section.handler';
import { RelatedArticle } from './related-article/related-article.handler';
import { RichMediaCard } from './rich-media-card/rich-media-card.handler';
import { RichTextHandler, RichTextSectionHandler } from './rich-text/rich-text.handler';
import { SaleSectionHandler } from './sale-section-handler/sale-section.handler';
import { SalesTileListSection } from './sale-tile-list-section-handler/sale-tile-list-section.handler';
import { SalesTileSection } from './sale-tile-section.handler/sale-tile-section.handler';
import { SavingsCenterSectionHandler } from './savings-center-section/savings-center-section.handler';
import { SiteWideSaleSectionHandler } from './sitewide-sale-section-handler/sitewide-sale-section.handler';
import { SpecialPricingFormPlaceholder } from './special-pricing-form-placeholder/special-pricing-form-placeholder.handler';
import { StoreHoursPlaceholder } from './store-hours-placeholder/store-hours-placeholder.handler';
import { SupportMenuSection, SupportMenuLink } from './support-menu/support-menu.handler';

const LoadableNonstockFormPlaceholder = loadable(
	() => import(/* webpackChunkName: "nonstock-placeholder" */ './nonstock-form-placeholder/nonstock-form-placeholder.handler'),
	{ resolveComponent: ({ NonstockFormPlaceholder }) => NonstockFormPlaceholder }
);

const LoadableManufacturerWarrantiesPlaceholder = loadable(
	() =>
		import(
			/* webpackChunkName: "manufacturer-warranties-placeholder" */ './manufacturer-warranties-placeholder/manufacturer-warranties-placeholder.handler'
		),
	{ resolveComponent: ({ ManufacturerWarrantiesPlaceholder }) => ManufacturerWarrantiesPlaceholder }
);

const LoadableFrequentlyAskedQuestions = loadable(
	() =>
		import(
			/* webpackChunkName: "frequently-asked-questions" */ '../../content-components/frequently-asked-questions/frequently-asked-questions.component'
		),
	{ resolveComponent: ({ FrequentlyAskedQuestionsComponent }) => FrequentlyAskedQuestionsComponent }
);

const LoadableProductTilesSection = loadable(
	() => import(/* webpackChunkName: "product-tiles-section" */ './product-tiles-section/product-tiles-section.handler'),
	{ resolveComponent: ({ ProductTilesSection }) => ProductTilesSection }
);

const LoadableRelatedArticlesSection = loadable(
	() => import(/* webpackChunkName: "related-articles-section" */ './related-articles-section/related-articles-section.handler'),
	{ resolveComponent: ({ RelatedArticlesSection }) => RelatedArticlesSection }
);

// imported by render helper, provides mapping of construct types to render handlers
export const contentHandlers = {
	'CardContent:group-item': MediaCard,
	'CardContent:group-item-rich': RichMediaCard,
	'CardContent:default': ContentCardHandler,
	'ObjectContent:media-group': MediaGroup,
	'ListContent:default': ListContentHandler,
	'RichTextContent:default': RichTextHandler,
	'ObjectContent:frequently-asked-questions': LoadableFrequentlyAskedQuestions,
	'ObjectContent:sale-section@2': SaleSectionHandler,
	'ObjectContent:sitewide-sale-section@1': SiteWideSaleSectionHandler,
	'ObjectContent:support-menu@1:menu-section': SupportMenuSection,
	'ObjectContent:support-menu@1:menu-link': SupportMenuLink,
	'ObjectContent:placeholder-store-hours': StoreHoursPlaceholder,
	'ObjectContent:placeholder-email-subscribe-form': EmailSubscribePlaceholder,
	'ObjectContent:placeholder-contact-us-form': ContactUsPlaceholder,
	'ObjectContent:placeholder-special-pricing-form': SpecialPricingFormPlaceholder,
	'ObjectContent:placeholder-horizontal-divider': HorizontalDividerPlaceholder,
	'ObjectContent:nonstock-form-placeholder': LoadableNonstockFormPlaceholder,
	'ObjectContent:general-content-group': GeneralContentGroup,
	'ObjectContent:cta': CallToAction,
	'ObjectContent:cta-section@1': CallToActionSection,
	'ObjectContent:placeholder-manufacturer-warranties': LoadableManufacturerWarrantiesPlaceholder,
	'ObjectContent:rich-text-section@1': RichTextSectionHandler,
	'ObjectContent:product-tiles-section@1': LoadableProductTilesSection,
	'ObjectContent:group-items-section@1': GroupItemsSection,
	'ObjectContent:related-articles-section': LoadableRelatedArticlesSection,
	'ObjectContent:related-article': RelatedArticle,
	'ObjectContent:hero-media@1': HeroMediaHandler,
	'ObjectContent:content-author@1': ContentAuthorHandler,
	'ObjectContent:content-section@1': ContentSection,
	'ObjectContent:content-section-full@1': ContentSectionFull,
	'ObjectContent:email-form-section@1': EmailFormSection,
	'ObjectContent:category-section': CategorySection,
	'ObjectContent:brand-section': BrandSection,
	'ObjectContent:savings-center-section@3': SavingsCenterSectionHandler,
	'ObjectContent:doorbuster-tile-section': DoorbusterSection,
	'ObjectContent:sale-tile-section': SalesTileListSection,
	'ObjectContent:sale-tile@1': SalesTileSection,
	'ObjectContent:pull-quote-section@1': PullQuoteSection
};
