import React, { FunctionComponent, useState } from 'react';
import { formatPrice, pluralize } from '../../../helpers/general-helper/general-helper';
import { ProductCardContent } from '../../../types/construct.types';
import { Link, MaybeLink } from '../../common-components/link/link.component';
import { Popover } from '../../common-components/popover/popover.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { Rating } from '../../product-components/rating/rating.component';
import { ChevronRightIcon } from '../../svg/icons.component';

export type ArticleProductCardProps = {
	product: ProductCardContent;
};

export const ArticleProductCard: FunctionComponent<ArticleProductCardProps> = ({ product }) => {
	const [isVisible, setIsVisible] = useState(false);
	const { image, url, detail } = product;

	if (!detail) {
		// product data is missing
		return null;
	}

	const { manufacturer, startingPrice, description, model, averageRating, reviewCount, finishes } = detail;

	const handleToggle = () => setIsVisible(!isVisible);

	return (
		<div className="ba b--theme-grey-light pa3 flex flex-column items-center">
			<MaybeLink url={url}>
				<CloudinaryImage
					className="center"
					description={image?.description ?? ''}
					options={{ type: image?.imageType, height: 220, width: 220 }}
					useDimensions={false}
					publicID={image?.id}
				/>
			</MaybeLink>
			<div className="flex flex-column h-100 mt2">
				<div className="lh-copy" style={{ minHeight: '6rem' }}>
					<MaybeLink url={url} className="theme-grey-darker tc3-copy" underlineHover={true}>
						<span className="fw6">{manufacturer}</span> <span className="fw3 fw2-ns">{description}</span>
					</MaybeLink>
					<div className="f6 theme-grey truncate">
						Model: <span className="b">{model}</span>
					</div>
				</div>

				<div style={{ minHeight: '5rem' }}>
					<div className="mv2 f7 f5-ns fw6 theme-primary">
						<span>Starting at {formatPrice(startingPrice)}</span>
					</div>
					{url && (
						<>
							<Rating
								readOnly={true}
								ratingValue={averageRating}
								reviewCount={reviewCount}
								url={`${url}#reviews`}
								className="flex f6 mb2"
							/>
							<div>
								{finishes && finishes.length > 0 && (
									<Popover
										ariaLabel="Show finishes"
										isVisible={isVisible}
										direction="top"
										onToggle={handleToggle}
										toggleElement={
											<span className="f6 theme-primary pointer">
												Available in {finishes.length}{' '}
												{pluralize('Finish', finishes.length, [{ singular: 'finish', plural: 'Finishes' }])}
											</span>
										}>
										<ul className="ma0 pv0 pl4">
											{finishes?.map((finish) => (
												<li key={finish.id}>
													<Link className="f6" url={`${url}?uid=${finish.id}`}>
														{finish.title}
													</Link>
												</li>
											))}
										</ul>
									</Popover>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export type DoorBusterProductCardProps = {
	product: ProductCardContent;
};

export const DoorBusterProductCard: FunctionComponent<DoorBusterProductCardProps> = ({ product }) => {
	const { image, url, detail } = product;

	if (!detail) {
		// product data is missing
		return null;
	}

	const { manufacturer, startingPrice, model } = detail;

	return (
		<div className="ba b--theme-grey-light pa3 flex flex-column items-center">
			<MaybeLink url={url} className="w-100 tc">
				<CloudinaryImage
					className="center"
					description={image?.description ?? ''}
					options={{ type: image?.imageType, height: 220, width: 220 }}
					useDimensions={false}
					publicID={image?.id}
				/>
				<div className="mt2">
					<div className="dn db-ns lh-copy theme-grey">
						<span className="fw6">{manufacturer}</span> <span className="b">{model}</span>
					</div>
					<div className="mv3 f7 f5-ns fw6 theme-primary">
						<span className="dib dn-ns">Starting at </span>
						<span>{formatPrice(startingPrice)}</span>
					</div>
					<div className="ba b--theme-grey-light pa2 theme-grey">
						<div className="dib">
							<span>Save Now</span>
							<ChevronRightIcon className="v-mid ml3" />
						</div>
					</div>
				</div>
			</MaybeLink>
		</div>
	);
};
