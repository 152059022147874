import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TachyonsSpacing } from '../../../types/general.types';
import { divider } from './divider.css';

export type DividerProps = {
	horizontalSpacing?: TachyonsSpacing;
	verticalSpacing?: TachyonsSpacing;
};

export const Divider: FunctionComponent<PropsWithChildren<DividerProps>> = ({ children, horizontalSpacing = 3, verticalSpacing = 3 }) => (
	<div data-testid="divider" className={`${divider} ph${horizontalSpacing} pv${verticalSpacing} flex`}>
		<span className={`ph${horizontalSpacing} b theme-grey`}>{children}</span>
	</div>
);
