import React from 'react';
import { ContentComponent, SaleTileSection } from '../../../../types/construct.types';
import { LinkButton } from '../../../buttons/link-button/link-button.component';
import { MaybeLink } from '../../../common-components/link/link.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { addContentHandler } from '../add-content-handler';
import { tilesStyle } from './sale-tile-section.handler.css';

export const SalesTileSection: ContentComponent<SaleTileSection> = ({ content }) => {
	const {
		headline,
		cta_url: { linkUrl },
		media_image: { image },
		coupon_code,
		expires_text,
		percent_off_text,
		percent_off,
		brand
	} = content.fields;

	const formattedPercentOffText = percent_off && percent_off_text ? percent_off_text.replace('xx', percent_off) : '';
	const logo = brand?.items[0]?.detail?.brand?.logo;

	return (
		<div className={`${tilesStyle}`}>
			<MaybeLink url={linkUrl} color="black">
				<div className={`flex flex-column bg-theme-white items-center pa2 ba b--theme-grey-light`}>
					<div style={{ minHeight: '30px' }}>
						{logo && (
							<div data-testid="brand-logo">
								<CloudinaryImage options={{ width: 218, height: 30 }} publicID={logo.id} description={logo.description} />
							</div>
						)}
					</div>
					<div className="w-100 justify-center" style={{ maxHeight: '220px', maxWidth: '228px' }}>
						{image ? (
							<div data-testid="sales-tile-image" className="aspect-ratio " style={{ paddingBottom: '87.71%' }}>
								<CloudinaryImage
									className="aspect-ratio--object"
									options={{ width: 228, height: 200, crop: 'thumb' }}
									useDimensions={false}
									publicID={image.id}
									description={image.description}
								/>
							</div>
						) : null}
					</div>
					<div className="flex flex-column justify-center w-100">
						{headline && (
							<h5 className="normal tc ma0 theme-dark-grey-ns pt3 mb3" style={{ minHeight: '3rem' }}>
								<span>
									<strong className="tc2-title">{headline}</strong>
								</span>
							</h5>
						)}
						{formattedPercentOffText && (
							<div className="nowrap tc pb2">
								<div className="tc1-title theme-primary b">
									<span>{formattedPercentOffText}</span>
								</div>
							</div>
						)}
						{coupon_code && (
							<div className="truncate flex pb2 flex justify-center">
								<div className="theme-emphasis b mr2">Coupon:</div>
								<div className="theme-emphasis b truncate">{coupon_code}</div>
							</div>
						)}
						{expires_text && <div className="nowrap pb2 tc f7 theme-grey">Expires on {expires_text}</div>}
						<div className="w-100 pa1">
							<LinkButton url={linkUrl} buttonStyle="SECONDARY">
								Save Now
							</LinkButton>
						</div>
					</div>
				</div>
			</MaybeLink>
		</div>
	);
};

addContentHandler('sale-tile@1', 'ObjectContent', 'sale-tile@1', SalesTileSection);
