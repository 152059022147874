import React, { FunctionComponent } from 'react';
import { SMALL_MAX_PX } from '../../../constants/breakpoints';
import { Variation } from '../../../helpers/images/images.helper';
import { CardContent } from '../../../types/construct.types';
import { MaybeLink } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ChevronRightIcon } from '../../svg/icons.component';
import { flexBasisCard, titleTheme, cardMinWidth, infoWrapper } from './sale-category-content-card.css';

export type SaleCategoryContentCardProps = CardContent;

export const SaleCategoryContentCard: FunctionComponent<SaleCategoryContentCardProps> = ({ image, title, url, cardFields }) => {
	const description = image?.description || title || '';
	const variations: Variation[] = [{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: 45, height: 45 } }];
	return (
		<div className={`${flexBasisCard} ${cardMinWidth} ph2-ns dib-ns`}>
			<MaybeLink url={url}>
				<section className="bg-theme-white ph3 pa3-ns pb3 flex justify-between items-center ba-ns bb b--theme-grey-light">
					<div className="flex flex-column-ns w-100 items-center gc2">
						{image && (
							<CloudinaryImage
								options={{ width: 182, height: 182 }}
								variations={variations}
								useDimensions={false}
								publicID={image.id}
								description={description}
							/>
						)}
						<div className={`w-100 ${infoWrapper}`}>
							{title && <p className={`f6 normal tc-ns ma0 ${titleTheme} mb1-ns fw7-ns truncate w-90 pt2-ns`}>{title}</p>}
							{cardFields.percent_off && (
								<p className="f5 tc ma0 theme-emphasis mb1 dn db-ns">Save up to {String(cardFields.percent_off)}%</p>
							)}
						</div>
						<div className="dn flex-ns justify-center items-center ba b--theme-grey-light theme-grey pv2 w-100">
							<div className="f5">Save Now </div>
							<ChevronRightIcon className="ml2 mt1 f5" />
						</div>
					</div>
					<ChevronRightIcon className="dn-ns theme-grey" />
				</section>
			</MaybeLink>
		</div>
	);
};
